import axios from "axios";
import cookie from "js-cookie";
import DefaultResponseHandler from "./ResponseHandler";
import {
  browserName,
  fullBrowserVersion,
  osVersion,
  osName,
} from "react-device-detect";
// const queryString = require('query-string');
import qs from 'querystring';
const TOKEN_KEY = "customerToken";

const baseURL = process.env.REACT_APP_API_DOMAIN;
// const baseURL = 'https://api.bennybutton.cloud/';
const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
  // "Set-Cookie": process.env.REACT_APP_API_COOKIE,
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": true
};

const apiInstance = axios.create({
  baseURL,
  headers: {
    ...headers,
    "BB-API-ACCESS-KEY": process.env.REACT_APP_API_ACCESS_KEY,
    // "BB-API-ACCESS-KEY": 'FNQSWFG4H18LBB2ULPROD',
  },
});

const apiWithKey = axios.create({
  baseURL,
  headers: {
    ...headers,
    "BB-API-ACCESS-KEY": process.env.REACT_APP_API_ACCESS_KEY,
    // "BB-API-ACCESS-KEY": 'FNQSWFG4H18LBB2ULPROD',
  },
});

const apiController = "/v1/";
const API_LOGIN = () => `${apiController}session/login`;
const API_SIGNUP = () => `${apiController}session/signup`;
const API_RESET_PASSWORD = () => `${apiController}session/reset-password`;
const API_USER = () => `${apiController}user`;
const API_CHANGE_PASSWORD = () => `${apiController}session/change-password`;
const API_CHANGE_EMAIL = () => `${apiController}session/update-email`;

const API_ORGANISATIONS = () =>
  `${apiController}checkpoint-admin/all-organisations`;

const API_ORGANISATION = (tag) => `${apiController}organisation/${tag}`;

const API_SINGLE_ORGANISATION = (tag) =>
  `${apiController}checkpoint-admin/organisation/${tag}`;

const API_ORGANISATION_TIERS = (tag) =>
  `${apiController}checkpoint-admin/organisation-tiers/${tag}`;

const API_COUNTRIES = () =>
  `${apiController}checkpoint-admin/all-iso-countries`;

const API_SET_RESOURCE_CLICKED = () => `${apiController}resource`;

const API_GET_DASHBOARD_CHECKIN = () => `${apiController}analytics/checkin`;
const API_GET_REAL_TIME_CHECKIN = () => `${apiController}analytics/rt-check-ins`;
const API_GET_DASHBOARD_FOCUS_AREA = () =>
  `${apiController}analytics/focus-area`;

const API_GET_FORM_ID = () =>
  `${apiController}analytics/users-rt-checkin-form-id`;

const API_GET_PROGRESS_SUMMARY = () =>
  `${apiController}analytics/action-progress-summary`;

const API_CHECK_PERMISSION = () => `${apiController}user/reporting-permission`;
const API_SEND_OS_INFO = () => `${apiController}session/audit`;

const API_GET_HISTORY = () =>
  `${apiController}check-in/user-history`;

const API_GET_TYPEFORM_RESPONSE = (id) => `${apiController}modular-profiler/typeform-response-weighted/${id}`;

const API_GET_MULTI_TYPEFORM_RESPONSE = (id) => `${apiController}modular-profiler/typeform-responses-weighted?typeform-tokens=${id}`;

const API_GET_RECENT_RESPONSE = () => `${apiController}modular-profiler/recent-response-token-for-each-factor`;

const API_GET_FACTOR_RESPONSE = (type, limit) => `${apiController}modular-profiler/recent-responses?limit=${limit}&focus=${type}`;

const API_GET_PROFILER_CHECKIN = () => `${apiController}analytics/modular-profiler`;

const API_GET_RESOURCE_LIKE = (resourceId) =>
  `${apiController}resource/latest/${resourceId}`;

const API_GET_RESOURCE_COMPLETE = (resourceId) =>
  `${apiController}resource/latest/${resourceId}`;

const API_LIKE_RESOURCE = () => `${apiController}resource`;

const API_GET_COMPARISONS = () => `${apiController}analytics/modular-profiler-report-object`;

const API_GET_BENCHMARK_DATA = () => `${apiController}analytics/modular-profiler-benchmark-data`;

const API_GET_CHECKIN_BENCHMARK_DATA = () => `${apiController}analytics/checkin-benchmark-data`;

const API_COMMENTRY_REPORT = () => `${apiController}ai/openai-wellbeing-snapshot-analytics`;

const API_CONVERSATION_STARTERS = (formId, date) => `${apiController}ai/conversation-starters/${formId}?week-start-date=${date}`;

const API_TRENDS_AND_INSIGHTS = (formId) => `${apiController}ai/trends-and-insights/${formId}`;


let responseHandler;
const defaultResponseHandler = new DefaultResponseHandler();

/**
 * Utilities
 */

export const getAuthToken = () => cookie.get(TOKEN_KEY);

const setAuthorizationHeader = (token) => {
  if (!apiInstance.defaults.headers) {
    apiInstance.defaults.headers = {};
  }
  if (token) {
    apiInstance.defaults.headers.Authorization = `Bearer ${token}`;
    cookie.set(TOKEN_KEY, token, { expires: 2 });
  }
};

const removeAuthorizationHeader = () => {
  if (apiInstance.defaults.headers) {
    delete apiInstance.defaults.headers["Authorization"];
    cookie.remove(TOKEN_KEY);
  }
};

const setTemporaryResponseHandler = (handler) => {
  responseHandler = handler;
};

const executeRequest = (promise) => {
  return new Promise((resolve, reject) => {
    const handler =
      responseHandler && typeof responseHandler === "function"
        ? responseHandler
        : defaultResponseHandler;
    promise
      .then((response) => {
        handler.handleSuccess(response, resolve, reject);
        responseHandler = undefined;
      })
      .catch((error) => {
        // console.log({ error });
        handler.handleError(error, reject);
        responseHandler = undefined;

        if (error?.response?.status === 401) {
          removeAuthorizationHeader();
        }
      });
  });
};

const doGetRequest = (url, params, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  let tierParam = {};
  let tierKey = "";
  if (params) {
    for (const i in params) {
      if (i.includes("tier")) {
        tierParam[i] = params[i];
        tierKey = i;
        delete params[i];
      }
    }
  }
   if (params) {
    url += `?${qs.stringify(params)}`;
   }
  if (tierKey) {
    for (const i in tierParam) {
      url = `${url}&${i}=${tierParam[i]}`;
      break;
    }
  }
  return executeRequest(api.get(url, config));
};

const doPostRequest = (url, payload, config) => {
  const api = config?.withKey ? apiWithKey : apiInstance;
  return executeRequest(api.post(url, payload, config));
};

const doPutRequest = (url, payload, { withKey, signupKey } = {}) => {
  const api = withKey ? apiWithKey : apiInstance;
  const headers = signupKey ? { "BB-ORG-SIGNUP-KEY": signupKey } : {};

  return executeRequest(api.put(url, payload, { headers }));
};

// const doDeleteRequest = (url, payload, config) => {
//   return executeRequest(apiInstance.delete(url, payload, config));
// };

/**
 * Utilities
 */

/**
 * Authentication
 */
const login = (email, password) => {
  return doPostRequest(API_LOGIN(), {
    email: email,
    password: password,
  });
};

const signup = ({ signupKey, ...payload }) => {
  return doPutRequest(API_SIGNUP(), payload, {
    withKey: true,
    signupKey,
  });
};

const resetPassword = (email) => doPostRequest(API_RESET_PASSWORD(), { email });
/**
 * Authentication
 */

/**
 * Authenicated
 */
const getUser = ({ userToken }) => {
  if (userToken) {
    setAuthorizationHeader(userToken);
  }

  return doGetRequest(API_USER());
};

const changePassword = ({ password, existingPassword, email }) => {
  return doPostRequest(API_CHANGE_PASSWORD(), {
    email,
    password,
    oldPassword: existingPassword,
  });
};

export const changeEmail = ({ email }) => {
  return doPostRequest(API_CHANGE_EMAIL(), {
    email,
  });
};
export const getPermission = () => doGetRequest(API_CHECK_PERMISSION());

export const updateAccount = (data) => doPutRequest(API_USER(), data);

const sendOSInfo = async (eventType) => {
  let latitude = 0;
  let longitude = 0;
  const coords = localStorage.getItem("coords");
  if (coords) {
    const data = JSON.parse(coords);
    latitude = data.latitude;
    longitude = data.longitude;
  }

  const param = {
    fcmToken: null,
    uniqueDeviceIdentification: "checkpoint desktop build version",
    deviceMsisdn: "",
    eventType,
    browserInformation: `${browserName} ${fullBrowserVersion}`,
    deviceOperatingSystem: `${osName} ${osVersion}`,
    latitude,
    longitude,
  };
  doPostRequest(API_SEND_OS_INFO(), param);
};

export const getOrganisations = () =>
  doGetRequest(API_ORGANISATIONS(), {}, { withKey: true });

export const getOrganisationTiers = (tag) =>
  doGetRequest(API_ORGANISATION_TIERS(tag), {}, { withKey: true });

export const getOrganisation = (tag) => {
  doGetRequest(API_ORGANISATION(tag), {}, { withKey: true });
};

export const getSingleOrganisation = (tag) =>
  doGetRequest(API_SINGLE_ORGANISATION(tag), {}, { withKey: true });

export const getAllCountries = () =>
  doGetRequest(API_COUNTRIES(), null, { withKey: true });

export const setResourceClicked = (data) =>
  doPostRequest(API_SET_RESOURCE_CLICKED(), data);

export const getResourceComplete = (resourceId, param) =>
  doGetRequest(API_GET_RESOURCE_COMPLETE(resourceId), param);

export const getResourceLike = (resourceId, param) =>
  doGetRequest(API_GET_RESOURCE_LIKE(resourceId), param);

export const likeResource = ({ resourceId, isLike, uid }) => {
  doPostRequest(API_LIKE_RESOURCE(), {
    type: "LIKE",
    eventValue: isLike,
    resourceItemCode: resourceId,
    uid,
  });
};

export const completeResource = ({ resourceId, isComplete, uid }) => {
  doPostRequest(API_LIKE_RESOURCE(), {
    type: "COMPLETE",
    eventValue: isComplete,
    resourceItemCode: resourceId,
    uid,
  });
};

export const getDashboardCheckIn = (tag, options, tiers) =>
  doGetRequest(API_GET_DASHBOARD_CHECKIN(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getRealtimeCheckIn = (tag, options, tiers) =>
    doGetRequest(API_GET_REAL_TIME_CHECKIN(), {
      "organisation-tag": tag === "default" ? "all" : tag,
      ...options,
      ...tiers,
    });
  

export const getDashboardFocusArea = (tag, options, tiers) =>
  doGetRequest(API_GET_DASHBOARD_FOCUS_AREA(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getProgressSummary = (tag, options, tiers) =>
  doGetRequest(API_GET_PROGRESS_SUMMARY(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getHistoryCheckIn = (date) =>
  doGetRequest(API_GET_HISTORY(), {
    ...date,
  });

export const getComparisons = (tag, options, tiers) =>
  doGetRequest(API_GET_COMPARISONS(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getBenchmarkData = (tag, options, tiers) =>
  doGetRequest(API_GET_BENCHMARK_DATA(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
  });

export const getRealtimeBenchmark = (tag, options, tiers) =>
    doGetRequest(API_GET_CHECKIN_BENCHMARK_DATA(), {
      "organisation-tag": tag === "default" ? "all" : tag,
      ...options,
      ...tiers,
    });

export const getTypeformResponse = (id) => doGetRequest(API_GET_TYPEFORM_RESPONSE(id));

export const getRecentResponse = (id) => doGetRequest(API_GET_RECENT_RESPONSE(id));

export const getFactorResponse = (type, limit) => doGetRequest(API_GET_FACTOR_RESPONSE(type, limit));

export const getMultiTypeformResponse = (id) => doGetRequest(API_GET_MULTI_TYPEFORM_RESPONSE(id));

export const getProfilerCheckin = (tag, options, tiers, select) =>
  doGetRequest(API_GET_PROFILER_CHECKIN(), {
    "organisation-tag": tag === "default" ? "all" : tag,
    ...options,
    ...tiers,
    ...select
  });

  export const getTrendsAndInsights = (formId, options) =>
    doGetRequest(API_TRENDS_AND_INSIGHTS(formId), {
      ...options,
    });

export const getCommentryReport = (param) =>
  doPostRequest(API_COMMENTRY_REPORT(), param);

export const getConversationStarters = (formId, date) =>
  doGetRequest(API_CONVERSATION_STARTERS(formId, date));

export const getFormId = () =>
  doGetRequest(API_GET_FORM_ID());


/**
 * Authenicated
 */

const exported = {
  apiInstance,
  setTemporaryResponseHandler,
  setAuthorizationHeader,
  removeAuthorizationHeader,
  login,
  getUser,
  signup,
  resetPassword,
  changePassword,
  getPermission,
  sendOSInfo,
  getHistoryCheckIn,
  getTypeformResponse,
  getRecentResponse,
  getFactorResponse,
  getMultiTypeformResponse,
  getProfilerCheckin,
  getOrganisation,
  getComparisons,
  getBenchmarkData,
  getCommentryReport,
  getRealtimeCheckIn,
  getFormId,
  getConversationStarters,
  getTrendsAndInsights,
  getRealtimeBenchmark
};

export default exported;
